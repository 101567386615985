<template>
  <div class="bg-white">
    <b-row class="px-5">
      <b-col xl="2" lg="3" md="2" sm="4">
        <b-form-group
            id="input-group-1"
            label="Email address:"
            label-for="input-1"
        >
          <b-form-input
              id="input-1"
              v-model="form.email"
              type="password"
              placeholder="Enter email"
              required
              size="xs"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col xl="2" lg="3" md="2" sm="4">
        <b-form-group
            id="input-group-1"
            label="Email address:"
            label-for="input-1"
        >
          <b-form-input
              id="input-1"
              v-model="form.email"
              type="email"
              placeholder="Enter email"
              required
              size="xs"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col xl="2" lg="3" md="2" sm="4">
        <b-form-group
            id="input-group-1"
            label="Email address:"
            label-for="input-1"
        >
          <b-form-input
              id="input-1"
              v-model="form.email"
              type="email"
              placeholder="Enter email"
              required
              size="xs"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col xl="2" lg="3" md="2" sm="4">
        <b-form-group
            id="input-group-1"
            label="Email address:"
            label-for="input-1"
        >
          <b-form-input
              id="input-1"
              v-model="form.email"
              type="email"
              placeholder="Enter email"
              required
              size="xs"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col xl="2" lg="3" md="2" sm="4">
        <b-form-group
            id="input-group-1"
            label="Email address:"
            label-for="input-1"
        >
          <b-form-input
              id="input-1"
              v-model="form.email"
              type="email"
              placeholder="Enter email"
              required
              size="xs"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col xl="2" lg="3" md="2" sm="4">
        <b-form-group
            id="input-group-1"
            label="Email address:"
            label-for="input-1"
        >
          <b-form-input
              id="input-1"
              v-model="form.email"
              type="email"
              placeholder="Enter email"
              required
              size="xs"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <div>
      <b-form-select v-model="selected" :options="options" :select-size="4" :multiple="true"></b-form-select>
      <div class="mt-3">Selected: <strong>{{ selected }}</strong></div>
    </div>
  </div>
</template>
<script>
import {
  CalendarView,
  CalendarViewHeader,
  CalendarMathMixin,
} from "vue-simple-calendar";
import moment from "moment";
import {GET_ITEMS} from "@/core/services/store/REST.module";
import {LARAVEL_DATE_FORMAT, LARAVEL_DATE_TIME_FORMAT, MOMENT_SYSTEM_DATE_FORMAT} from "@/core/config/constant";
import DashboardBox from "@/assets/components/DashboardBox";
//// The next two lines are processed by webpack. If you're using the component without webpack compilation,
// you should just create <link> elements for these. Both are optional, you can create your own theme if you prefer.
require("vue-simple-calendar/static/css/default.css")
require("vue-simple-calendar/static/css/holidays-us.css")
export default {
  name: "Ajadam",
  components: {
    DashboardBox,
    CalendarView,
    CalendarViewHeader,
  },
  mixins: [CalendarMathMixin],
  data() {
    return {
      form: {},
      selected: null,
      options: [
        { value: null, text: 'Please select some item' },
        { value: 'a', text: 'This is option a' },
        { value: 'b', text: 'Default Selected Option b' },
        { value: 'c', text: 'This is option c' },
        { value: 'd', text: 'This one is disabled', disabled: true },
        { value: 'e', text: 'This is option e' },
        { value: 'e', text: 'This is option f' }
      ]
    };
  },
  computed: {
  },
  mounted() {
  },
  methods: {
  },
}
</script>
<style scoped>
html,
body {
  height: 100%;
  margin: 0;
  background-color: #f7fcff;
}
#app {
  display: flex;
  flex-direction: row;
  font-family: Calibri, sans-serif;
  width: 95vw;
  min-width: 30rem;
  max-width: 100rem;
  min-height: 40rem;
  margin-left: auto;
  margin-right: auto;
}
.calendar-controls {
  margin-right: 1rem;
  min-width: 14rem;
  max-width: 14rem;
}
.calendar-parent {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: hidden;
  max-height: 80vh;
  background-color: white;
}
/* For long calendars, ensure each week gets sufficient height. The body of the calendar will scroll if needed */
.cv-wrapper.period-month.periodCount-2 .cv-week,
.cv-wrapper.period-month.periodCount-3 .cv-week,
.cv-wrapper.period-year .cv-week {
  min-height: 6rem;
}
/* These styles are optional, to illustrate the flexbility of styling the calendar purely with CSS. */
/* Add some styling for items tagged with the "birthday" class */
.theme-default .cv-item.birthday {
  background-color: #e0f0e0;
  border-color: #d7e7d7;
}
.theme-default .cv-item.birthday::before {
  content: "\1F382"; /* Birthday cake */
  margin-right: 0.5em;
}
.box{
  background-color: white;
  border-radius: 15px;
  padding: 20px;
  margin: 5px;
}
.checkboxClass{
  display: inline-block;
  font-size:1em !important;
}
input[type=checkbox]
{
  /* Double-sized Checkboxes */
  -ms-transform: scale(01); /* IE */
  -moz-transform: scale(1); /* FF */
  -webkit-transform: scale(1); /* Safari and Chrome */
  -o-transform: scale(1); /* Opera */
  transform: scale(1);
  display: inline-block;
  width: 25px;
  height: auto;
}
.field label.checkbox {
  font-size: 1rem;
  font-weight: 300 !important;
  line-height: 1.1rem;
}
select.btn-mini {
  line-height: 14px;
  width: auto;
}
.checkbox-wrapper {
  white-space: nowrap
}
.checkbox {
  vertical-align: top;
  display:inline-block
}
.checkbox-label {
  white-space: normal;
  display:inline-block
}
.calendar_h{
  height: 600px !important;
}
</style>